<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->

    <b-overlay :show="formShow" rounded="sm" no-fade>
      <b-row>
        <b-col cols="12" xl="3" md="4" sm="12" xs="12">
          <b-card style="height: 100%;">
            <b-card-body>
              <b-row>
                <!-- image upload -->
                <b-col
                  cols="12"
                  xl="12"
                  md="12"
                  sm="3"
                  xs="12"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-img-lazy
                    rounded
                    thumbnail
                    v-bind="mainProps"
                    style="width: 100%"
                    ref="refPreviewEl"
                    :src="imageUrl"
                  />
                </b-col>

                <b-col
                  cols="12"
                  xl="12"
                  md="12"
                  sm="9"
                  xs="12"
                  class="justify-content-center align-items-center text-center"
                >
                  <h2 class="ml-1 toCapitalFirst mt-3">
                    {{ userInfo.name + " " + userInfo.surname }}
                  </h2>

                  <table
                    class="modern-table mt-1 justify-content-center align-items-center text-center w-100"
                  >
                    <tbody>
                      <tr></tr>

                      <tr class="mb-1">
                        <td class="emoji">
                          <b-badge pill variant="dark"
                            >{{ userInfo.department.title }} /
                            {{ userInfo.site.title }}</b-badge
                          >
                        </td>
                      </tr>

                      <tr class="mb-1">
                        <th>📝 Employment Type</th>
                      </tr>

                      <tr class="mb-1">
                        <td class="emoji">{{ userInfo.employment }}</td>
                      </tr>
                      <tr>
                        <th>📆 Hired on</th>
                      </tr>
                      <tr class="mb-1">
                        <td class="emoji">
                          {{ dateFormat(userInfo.hired_on) }}
                        </td>
                      </tr>

                      <tr>
                        <th>📆 Left on</th>
                      </tr>
                      <tr class="mb-1">
                        <td class="emoji">
                          {{
                            userInfo.left_on
                              ? dateFormat(userInfo.left_on)
                              : "N/A"
                          }}
                        </td>
                      </tr>

                      <tr>
                        <th>💼 Position</th>
                      </tr>
                      <tr class="mb-1">
                        <td class="emoji" style="text-transform: capitalize;">
                          {{ userInfo.role }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12" xl="9" md="8" sm="12" xs="12">
          <b-card style="height: 100%;">
            <b-row>
              <b-col cols="12">
                <b-tabs horizontal fill v-model="activeTab">
                  <b-tab title="Personal" :icon="'fas fa-user'">
                    <template #title>🧍 Personal</template>

                    <b-card-body v-if="$Can('view_personnel_tab')">
                      <b-row>
                        <b-col cols="12">
                          <b-row class="justify-content-end">
                            <div>
                              <b-button
                                v-show="$Can('user_send_password')"
                                class="mr-1 btn"
                                @click="sendNewPassword(userInfo)"
                                variant="secondary"
                                :disabled="true"
                              >
                                Send New Password
                                <feather-icon icon="SendIcon" />
                              </b-button>
                              <b-button
                                v-show="$Can('view_activate_user_button')"
                                class="mr-1 btn"
                                v-if="userInfo.status != 'active'"
                                @click="activateEmployee"
                                variant="success"
                              >
                                Activate
                              </b-button>

                              <b-button
                                v-show="$Can('view_deactivate_user_button')"
                                class="mr-1 btn"
                                v-if="userInfo.status != 'passive'"
                                @click="terminateEmployee"
                                variant="warning"
                              >
                                Deactivate
                              </b-button>

                              <b-button
                                v-show="$Can('view_edit_user_button')"
                                class="mr-1 btn"
                                @click="editEmployee"
                                variant="primary"
                              >
                                Edit
                              </b-button>
                            </div>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card-body>

                    <b-card-body class="text-danger text-center" v-else>
                      No Permission
                    </b-card-body>

                    <b-row v-if="$Can('view_personnel_tab')">
                      <table class="table">
                        <tbody class="text-left">
                          <tr>
                            <td class="item-price  w-25">First name</td>
                            <td class="w-75">{{ userInfo.name }}</td>
                          </tr>

                          <tr>
                            <td class="item-price ">Last name</td>
                            <td>{{ userInfo.surname }}</td>
                          </tr>

                          <tr>
                            <td class="item-price ">Phone</td>
                            <td>{{ userInfo.phone }}</td>
                          </tr>

                          <tr>
                            <td class="item-price ">Email</td>
                            <td>{{ userInfo.email }}</td>
                          </tr>

                          <tr>
                            <td class="item-price ">Role</td>
                            <td>{{ userInfo.role }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-row>
                  </b-tab>

                  <b-tab>
                    <template #title>📅 Schedule</template>
                    <user-schedule v-if="activeTab == 1"></user-schedule>
                  </b-tab>

                  <b-tab v-if="$Can('employees_time_off_tab')">
                    <template #title>⚙️ Time Off</template>

                    <validation-observer ref="timeOffRuless">
                      <b-form>
                        <b-row
                          v-if="userInfo.id"
                          class="mt-2  border-bottom pb-1"
                        >
                          <b-col sm="3" md="3" xl="3">
                            <b-form-group
                              label="Availability"
                              label-for="availability"
                            >
                              <validation-provider
                                name="Availability"
                                #default="{ errors }"
                                rules="required"
                              >
                                <v-select
                                  v-model="availability"
                                  :options="availabilityOptions"
                                  :reduce="(val) => val.value"
                                  :clearable="false"
                                  input-id="availability"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col xl="3" md="3" sm="3">
                            <b-form-group
                              label="Date Range"
                              label-for="Date Range"
                            >
                              <validation-provider
                                name="Date Range"
                                #default="{ errors }"
                                rules="required"
                              >
                                <flat-pickr
                                  placeholder="Date Range"
                                  v-model="rangeDate"
                                  class="form-control"
                                  :config="{
                                    mode: 'multiple',
                                    dateFormat: 'd-m-Y',
                                  }"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col xl="6" md="6" sm="6" class="btn-small mt-2">
                            <b-button
                              @click="clearDate()"
                              variant="success"
                              class="mr-2"
                              >Clear</b-button
                            >
                            <b-button
                              v-show="$Can('employees_availabilty_function')"
                              type="submit"
                              @click.prevent="availabilityAdd"
                              variant="primary"
                            >
                              Add
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </validation-observer>
                    <b-row v-if="userInfo.id" class="mt-2">
                      <b-col cols="12">
                        <b-table
                          striped
                          hover
                          ref="refUserListTable"
                          class="position-relative"
                          :items="fetchAvailability"
                          filter-debounce="250"
                          responsive
                          :small="true"
                          head-variant="dark"
                          :fields="tableColumns"
                          show-empty
                          empty-text="No matching records found"
                        >
                          <template #cell(date)="data">
                            {{ dateFormat(data.value) }}
                          </template>

                          <template #cell(availability)="data">
                            <b-badge
                              v-if="data.value == 'A'"
                              pill
                              :variant="`light-success`"
                              class="text-capitalize"
                            >
                              Annual Leave
                            </b-badge>

                            <b-badge
                              v-if="data.value == 'S'"
                              pill
                              :variant="`light-warning`"
                              class="text-capitalize"
                            >
                              Sick Leave
                            </b-badge>

                            <b-badge
                              v-if="data.value == 'U'"
                              pill
                              :variant="`light-danger`"
                              class="text-capitalize"
                            >
                              Unpaid Leave
                            </b-badge>

                            <b-badge
                              v-if="data.value == 'H'"
                              pill
                              :variant="`info`"
                              class="text-capitalize"
                            >
                              Holiday
                            </b-badge>

                            <b-badge
                              v-if="data.value == 'O'"
                              pill
                              :variant="`secondary`"
                              class="text-capitalize"
                            >
                              Other Leave
                            </b-badge>
                          </template>

                          <template #cell(actions)="data">
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                            >
                              <template #button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="14"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="deleteTimeOff('R', data)"
                              >
                                <feather-icon icon="XIcon" />
                                <span class="align-middle ml-50">Delete</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab>
                    <template #title>⚙️ Report</template>
                    <user-report v-if="activeTab == 3"></user-report>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BModal,
  BAvatar,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BLink,
  BButton,
  BAlert,
  BCardHeader,
  BCardTitle,
  VBToggle,
  BTabs,
  BTab,
  BCardText,
  BProgress,
  BTable,
} from "bootstrap-vue";
import moment from "moment";
import userStoreModule from "../userStoreModule";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import UserSchedule from "./UserSchedule.vue";
import UserReport from "./UserReport.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BAvatar,
    BModal,
    BCardBody,
    BImg,
    BCardHeader,
    BCardTitle,
    BTabs,
    BCardText,
    BTab,
    BProgress,
    vSelect,
    flatPickr,
    BTable,
    ValidationProvider,
    ValidationObserver,
    UserSchedule,
    UserReport,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var userInfo = {
      timeline: [],
      site: {
        title: "N/A",
      },
      department: {
        title: "N/A",
      },
      profile: null,
      id: null,
      name: null,
      surname: null,
      email: null,
      role: null,
      birthday: null,
      phone: null,
      address: null,
      city: null,
      postcode: null,
      status: null,
    };
    const availabilityOptions = [
      { label: "Annual Leave", value: "A" },
      { label: "Holiday", value: "H" },
      { label: "Sick Leave", value: "S" },
      { label: "Unpaid Leave", value: "U" },
      { label: "Other", value: "O" },
    ];

    return {
      activeTab: 0,
      userInfo: userInfo,
      reportName: null,
      imageUrl: store.state.app.profileURL + "none.jpg",
      tempImage: null,
      profileURL: store.state.app.profileURL,

      required,
      alphaNum,
      email,

      baseURL: store.state.app.baseURL,

      employeeId: null,
      formShow: false,

      users: [],
      availabilityOptions,
      availability: null,
      startDate: null,
      endDate: null,
      rangeDate: null,
      fetchAvailability: [],

      tableColumns: [
        { key: "date", sortable: true, class: "text-center" },
        { key: "availability", sortable: true, class: "text-center" },
        { key: "actions", sortable: true, class: "text-center" },
      ],
      //FILES

      fetchTeams: [],

      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: "% ",
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      config: {
        dateFormat: "d-m-Y",
        mode: "range",
        locale: { firstDayOfWeek: 1 },
      },
    };
  },

  created() {
    this.getEmployeeById();
  },

  methods: {
    editEmployee() {
      router.push({
        name: "user-edit",
        params: { id: router.currentRoute.params.id },
      });
    },

    activateEmployee() {
      this.$swal({
        title: "Are you sure?",
        text: "",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Yes, activate the employee!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: "active",
            userId: router.currentRoute.params.id,
          };
          this.userInfo.status = "active";

          store.dispatch("user/changeStatus", tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successful",
                  text: "✔️ User Update Successful",
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    sendNewPassword(user) {
      if (user.id) {
        this.loading = true;
        store
          .dispatch("user/sendNewPassword", {
            user: user.id,
          })
          .then((res) => {
            if (res.data.info == "sent") {
              this.successToastMessage("Password sent successfully");
            } else {
              this.wrongToastMessage("User not found");
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      } else {
        this.wrongToastMessage("User id not found");
      }
    },

    terminateEmployee() {
      this.$swal({
        title: "Are you sure?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, deactivate the employee!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: "passive",
            userId: router.currentRoute.params.id,
          };
          this.userInfo.status = "passive";

          store.dispatch("user/changeStatus", tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successful",
                  text: "✔️ User Update Successful",
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    availabilityAdd() {
      this.$refs.timeOffRuless.validate().then((success) => {
        if (success) {
          var tempData = {
            date: null,
            availability: null,
            userId: null,
          };

          tempData.availability = this.availability;
          tempData.userId = router.currentRoute.params.id;
          let dateSplit = this.rangeDate.split(/[ ,]+/);
          let dateArray = [];
          dateSplit.forEach((element) => {
            dateArray.push(element);
          });

          tempData.date = dateArray;
          console.log(tempData);
          tempData.date.forEach((element) => {
            this.fetchAvailability.unshift({
              date: element,
              availability: tempData.availability,
              userId: tempData.userId,
            });
          });

          store
            .dispatch("user/employeeAvailability", tempData)
            .then((response) => {
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successful",
                    text: "✔️ Employee Update Successful",
                    icon: "ThumbsUpIcon",
                    variant: "success",
                  },
                });

                this.availability = null;
                this.clearDate();
                this.getEmployeeById();
              }
            });
        }
      });
    },

    deleteTimeOff(val, data) {
      this.$swal({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteAvailability/${data.item.id}`)
            .then((res) => {
              this.getEmployeeById();
            })
            .catch((error) => {
              console.log("error delete ", error);
            });
        }
      });
    },

    clearDate() {
      if (this.rangeDate != null && this.rangeDate != "") {
        this.startDate = null;
        this.endDate = null;
        this.rangeDate = null;
      }
    },

    getEmployeeById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.employeeId = parseInt(router.currentRoute.params.id);

        store
          .dispatch("user/fetchUser", { id: router.currentRoute.params.id })
          .then((response) => {
            this.userInfo = response.data;
            // console.log(response.data)
            this.fetchAvailability = response.data.availability.reverse() ?? [];

            this.imageUrl = this.profileURL + response.data.profile;

            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "An error occurred",
                text: "Please try again later or contact support.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.formShow = false;
      }
    },

    dateFormat(val) {
      if (val != null) {
        moment.locale("en-US");
        return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        return "N/A";
      }
    },

    formatPrice(value) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");

        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    successToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.user-title {
  width: 100%;
  font-weight: bold;
}

.user-badge {
  width: 50%;
  align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>

<style scoped>
.toCapitalFirst {
  text-transform: capitalize;
}

.modern-table {
  width: 50%;
}

.modern-table th {
  padding: 5px;
  font-weight: bold;
  color: #7f7f7f;
}

.modern-table td {
  padding: 5px;
}

.emoji {
  font-size: 12px;
  vertical-align: middle;
}

.item-price {
  font-weight: bold;
  color: #7f7f7f;
}
</style>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  min-width: 120px;
  max-width: 220px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: "\2713";
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: "\2713";
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-90 {
  width: 90%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-60 {
  width: 60%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: "\23F7";
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
